import Flatpickr from 'stimulus-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import 'flatpickr/dist/themes/light.css'

export default class extends Flatpickr {
  initialize() {
    this.config = {
      locale: Japanese,
      disableMobile: true,
    }
  }
}
